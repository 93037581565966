import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { ReportingObserver, ExtraErrorData } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";

import App from "./App";
import * as serviceWorker from "./serviceWorkerRegistration";
import "./tailwind.css";

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { onError } from "@apollo/client/link/error";

let URL = "http://localhost:8000/vms/graphql";

// @ts-ignore
if (window?.__RUNTIME_CONFIG__?.URL) {
  // @ts-ignore
  URL = window?.__RUNTIME_CONFIG__?.URL;
}

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn: "https://e3324dc8105945dfb82548d0e3c73d43@sentry.io/284536",
    integrations: [
      new ReportingObserver(),
      new ExtraErrorData(),
      new BrowserTracing({
        tracingOrigins: ["localhost", "regionofwaterloo.9802690.ca", "yrt.ca"],
      }),
    ],
    denyUrls: [
      // Twitter flakiness
      /platform\.twitter\.com/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment:
      // @ts-ignore
      window?.__RUNTIME_CONFIG__?.ENV ||
      process.env.REACT_APP_RELEASE_ENV ||
      "unknown",
    tracesSampleRate: 0.01,
  });
} else if (process.env.NODE_ENV !== "production") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

URL = process.env.REACT_APP_URL_OVERRIDE || URL;

// auth token
const token = window.localStorage.getItem("authToken") || uuidv4() || "unknown";
window.localStorage.setItem("authToken", token);
Sentry.configureScope(function (scope) {
  scope.setUser({ id: token });
});

// middleware
const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Frontend-Application": "React-Dashboard",
      "X-Frontend-Version": process.env.REACT_APP_SENTRY_RELEASE || "HEAD",
    },
  });

  return forward(operation).map((result) => {
    // Requires the server to emit a Access-Control-Expose-Headers header
    // containing x-request-id.
    // const headers = operation.getContext().response.headers;
    // const backendVersion = headers.get("x-request-id");
    // TODO: Do something with the Request ID here.
    return result;
  });
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    middlewareLink,
    createHttpLink({
      uri: URL,
      credentials: "same-origin",
    }),
  ]),
});

const app = (
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);
const root = createRoot(document.getElementById("root")!);
root.render(app);

serviceWorker.register();
